import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import MainView from "../views/MainView.vue";
import ErrorView from "../views/ErrorView.vue";
import MainPage from "../pages/MainPage.vue";
import StorePage from "../pages/StorePage.vue";
import LoginPage from "../pages/LoginPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import RewardsAndPromotions from "../pages/RewardsAndPromotions.vue";
import HowToCreateAccountOnStake from "../pages/HowToCreateAccountOnStake.vue";
import OurRewardsSystem from "../pages/OurRewardsSystem.vue";
import ProductDetailsSection from "@/sections/ProductDetailsSection.vue";
// import ChallengesPage from "@/pages/ChallengesPage.vue";

const routes = [
	{
		path: "/",
		component: MainView,
		meta: { alreadyAuth: true },
		children: [
			{
				path: "/home",
				alias: "/",
				component: MainPage,
			},
			{
				path: "/store",
				component: StorePage,
			},
			{
				path: "/login",
				component: LoginPage,
			},
			{
				path: "/profile",
				component: ProfilePage,
			},
			{
				path: "/rewards-and-promotions",
				component: RewardsAndPromotions,
			},
			// {
			// 	path: "/challenges",
			// 	component: ChallengesPage,
			// },
			// Separated child pages from the parent rewards-and-promotion
			{
				path: "/rewards-and-promotions/how-to-create-account-on-stake",
				component: HowToCreateAccountOnStake,
			},
			{
				path: "/rewards-and-promotions/our-rewards-system",
				component: OurRewardsSystem,
			},
			{
				path: "/product-details",
				component: ProductDetailsSection,
			},
		],
	},

	{
		path: "/:pathMatch(.*)*",
		name: "ErrorView",
		component: ErrorView,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,

	// eslint-disable-next-line
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	if (to.path === "/profile" && store.state.user === null) {
		next("/error-page");
	}
	else {
		next();
	}
});

export default router;
