<template>
    <section class="h-screen z-50 main-bg">
        <div class="h-full">
            <div class="flex flex-col justify-center items-center">
                <img loading="lazy" src="../assets/seham-logo.png" class="w-[300px] mt-20">
                <div class=" text-[#0FB97F] font-bold text-[30px] mx-auto uppercase">Loading...</div>
                <div class="mt-14 w-24 h-24 rounded-full animate-spin
                    border-y-8 border-solid border-green-500 border-t-transparent">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.main-bg {
  background-image: url("../assets/hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
</style>