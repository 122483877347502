<template>
	<section class="page-bg px-5 md:px-5 lg:px-8">
		<div
			class="flex flex-col justify-start items-start py-24 md:pt-44"
			style="font-family: Russo One, sans-serif"
		>
			<!-- Profile page -->
			<div class="text-left flex mb-10 ml-4">
				<h1 class="uppercase text-[#0fb97f] text-4xl md:text-5xl">
					Profile
				</h1>
			</div>
			<!-- Profile content -->
			<div class="flex border-8 border-[#029D65] w-full">
				<div class="profile-bg rounded-lg grow w-full shadow-xl">
					<div class="flex flex-col lg:flex-row justify-center items-center gap-10">
						<div class="flex flex-col lg:flex-row items-center justify-center pt-16 lg:pb-16">
							<img
								src="../assets/profile-logo.svg"
								alt="profile"
								class="w-44 h-44 rounded-full"
								v-if="!discordAvatar"
							/>
							<img
								:src="discordAvatar"
								alt="profile"
								class="w-44 h-44 rounded-full"
								v-else
							/>
							<span class="text-white ml-3 mt-4 text-3xl lg:text-5xl">
								{{ discordName ?? "Player #123456" }}
							</span>
							<span v-if="$store.state.user?.isVerified">
								<img
									src="../assets/verified-badge.png"
									alt="verified"
									class="w-8 h-8 lg:ml-2"
								/>
							</span>
						</div>
						<div class="text-center py-4">
							<button
								class="bg-[#0fb97f] px-8 py-3 text-xl text-black uppercase"
								@click="
									onEdit();
									if (buttonType === 'Edit') onSave();
								"
							>
								{{ buttonType }}
							</button>
						</div>
					</div>
					<div class="flex flex-col gap-y-12 lg:gap-y-0 lg:flex-row py-6 lg:py-2">
						<!-- Accounts -->
						<div class="uppercase text-center grow">
							<div class="flex flex-col">
								<h1 class="text-white text-5xl mb-8">Accounts</h1>
								<p class="uppercase text-white text-xl ml-2 mb-2">
									Discord ID: {{ discordId }}
								</p>
								<p class="uppercase text-gray-400 text-xl ml-2 mb-2">
									<label
										for="kickUsername "
										class="text-white text-xl ml-2 mb-2"
									>
										Kick username:
									</label>
									<input
										v-if="isEdit"
										type="text"
										id="kickUsername"
										v-model="kick"
										class="text-gray-400"
									/>
									<span v-else class="text-white">{{ kick }}</span>
								</p>
								<p class="uppercase text-gray-400 text-xl ml-2 mb-2">
									<label
										for="stakeUsername"
										class="text-white text-xl ml-2 mb-2"
									>
										Stake username:
									</label>
									<input
										v-if="isEdit"
										type="text"
										id="stakeUsername"
										v-model="stake"
										class="text-gray-400"
									/>
									<span v-else class="text-white">{{ stake }}</span>
								</p>
							</div>
						</div>
						<!-- Socials -->
						<div class="uppercase text-center grow">
							<div class="flex flex-col">
								<h1 class="text-white text-5xl text-center mb-8">Socials</h1>
								<ul>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Twitter:
											<input
												v-if="isEdit"
												type="text"
												v-model="twitter"
												class="text-gray-400"
											/>
											<span v-else class="text-white">{{ twitter }}</span>
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Instagram:
											<input
												v-if="isEdit"
												type="text"
												v-model="instagram"
												class="text-gray-400"
											/>
											<span v-else class="text-white">{{ instagram }}</span>
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Youtube:
											<input
												v-if="isEdit"
												type="text"
												v-model="youtube"
												class="text-gray-400"
											/>
											<span v-else class="text-white">{{ youtube }}</span>
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Tiktok:
											<input
												v-if="isEdit"
												type="text"
												v-model="tiktok"
												class="text-gray-400"
											/>
											<span v-else class="text-white">{{ tiktok }}</span>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="flex flex-col lg:flex-row">
						<div class="py-12 px-8">
							<p class="text-[#24FF00] font-thin">Disclaimer:</p>
							<ul class="uppercase">
								<li>
									<p class="text-[#24FF00] font-thin">
										Users must put the correct information onset during stake
										username registration through discord to avoid discrepancies
										in points or else points won’t be distributed correctly.
									</p>
								</li>
								<li>
									<p class="text-[#24FF00] font-thin">
										any loss of points, the seham team will not be held liable
										due to incorrect information entered on discord (this is
										also a way to prevent alt accounts farming for points) so
										please be careful in entering the right information.
									</p>
								</li>
								<li>
									<p class="text-[#24FF00] font-thin">
										for any changes, please go through discord and make a ticket
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- Purchase transactions -->
			<!-- <div class="text-left flex mt-12 mx-8 lg:mx-24 flex-col">
				<h1 class="uppercase text-[#0fb97f] mb-8 text-xl md:text-4xl">
					Purchase transactions
				</h1>
				<ul class="uppercase text-xl max-h-24 overflow-y-auto">
					<li
						class="text-white mb-1"
						v-for="transaction in transactions"
						:key="transaction.id"
					>
						{{ transaction.description }} on
						{{ transaction.type.toUpperCase() }} at
						{{ formatDate(transaction.createdAt) }}
					</li>
				</ul>
			</div> -->
		</div>
	</section>
</template>

<script>
import { getUser, saveUser } from "../api/users.ts";
import { mapGetters, mapMutations } from "vuex";
// import axios from "axios";

export default {
	data() {
		return {
			buttonType: "Edit",
			isEdit: false,
			discordId: null,
			discordName: null,
			discordAvatar: null,
			//socials
			twitter: null,
			instagram: null,
			youtube: null,
			tiktok: null,
			//accounts
			kick: null,
			stake: null,
			transactions: [],
		};
	},
	computed: {
		...mapGetters(["getItem"]),
	},
	methods: {
		...mapMutations(["UPDATE_USER"]),

		onEdit() {
			this.isEdit = !this.isEdit;
			this.buttonType = this.isEdit ? "Save" : "Edit";
		},

		async fetchData() {
			const token = localStorage.getItem("seham-access-token");
			if (!token) {
				localStorage.removeItem("vuex");
				localStorage.removeItem("seham-access-token");
				this.$router.push("/login");
			}
			// Get discord data
			this.discord = await getUser();

			// Socials
			this.twitter = this.discord.user.twitter;
			this.instagram = this.discord.user.instagram;
			this.youtube = this.discord.user.youtube;
			this.tiktok = this.discord.user.tiktok;
			// Accounts
			this.kick = this.discord.user.kick;
			this.stake = this.discord.user.stake;

			// Save discord data to vuex
			if (this.discord !== null) {
				this.UPDATE_USER(this.discord.user);
			}

			// Set discord data
			this.discordName = this.discord.user.displayName;
			this.discordId = this.discord.user.discordId;
			this.discordAvatar =
				"https://cdn.discordapp.com/avatars/" +
				this.discord.user.discordId +
				"/" +
				this.discord.user.profileImage +
				".png";
		},

		async onSave() {
			//socials
			await saveUser({
				twitter: this.twitter,
				instagram: this.instagram,
				youtube: this.youtube,
				tiktok: this.tiktok,
				//accounts
				kick: this.kick,
				stake: this.stake,
			});
		},

		// async onGetTransactions() {
		// 	try {
		// 		const baseUrl = process.env.VUE_APP_BASE_API;
		// 		const token = process.env.VUE_APP_AUTH_TOKEN;
		// 		await axios
		// 			.get(`${baseUrl}/api/user/transactions`, {
		// 				headers: {
		// 					Authorization: `Bearer ${token}`,
		// 					"x-api-key": process.env.VUE_APP_X_API_KEY,
		// 					"x-api-name": process.env.VUE_APP_X_API_NAME,
		// 				},
		// 			})
		// 			.then((res) => {
		// 				this.transactions = res.data.transactions;
		// 			});
		// 	} catch (error) {
		// 		console.error(error);
		// 	}
		// },
	},
	mounted() {
		this.fetchData();
	},
};
</script>

<style scoped>
.page-bg {
	background-image: url("../assets/page-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
}

.profile-bg {
	background-image: linear-gradient(
			to bottom right,
			rgba(87, 85, 85, 0.5),
			rgba(2, 155, 56, 0.5),
			rgba(8, 87, 61, 0.5)
		),
		url("../assets/profilepage-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
</style>
