<template>
	<!-- Navbar start -->
	<nav
		:class="{
			'bg-black shadow-md transition-opacity bg-opacity-80':
				isScrolled || showMenu,
			'bg-transparent': !isScrolled && !showMenu,
		}"
		class="w-full fixed p-2 px-5 md:px-5 lg:px-8 flex justify-between z-40 transition-all h-20"
	>
		<a
			@click="
				showMenu = false;
				this.isScrolled = false;
			"
			href="/#main"
		>
			<div class="h-full flex flex-row justify-between items-center">
				<img src="../assets/sehamx-title.png" class="" alt="logo" />
			</div>
		</a>

		<button role="menubar" class="lg:hidden" @click="toggleMenu()">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="white"
				class="w-9 h-8"
			>
				<path
					fill-rule="evenodd"
					d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>

		<div
			:class="{
				'bg-opacity-80 bg-black lg:bg-transparent': isScrolled || showMenu,
				'bg-opacity-80': !isScrolled && !showMenu,
				hidden: !showMenu && isMobile(),
				flex: showMenu || !isMobile(),
			}"
			class="absolute left-0 right-0 mt-[72.1px] md:mt-[71px] lg:mt-2 shadow lg:flex flex-col gap-4 justify-end items-start lg:item-center p-2 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 transition-all"
			role="menu"
			:aria-expanded="showMenu.toString()"
		>
			<div
				class="flex flex-col gap-[20px] lg:mb-0 lg:flex-row justify-evenly uppercase whitespace-nowrap text-lg text-white"
				style="font-family: Rubik, sans-serif"
			>
				<!-- <router-link @click="showMenu = false; this.isScrolled = false;" to="/home" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Home</router-link> -->
				<a
					@click="showMenu = false"
					href="/#leaderboard"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Leaderboard</a
				>
				<a
					@click="showMenu = false"
					href="/#socials"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Socials</a
				>
				<a
					@click="showMenu = false"
					href="/#schedule"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Schedule</a
				>
				<!-- <a
					@click="showMenu = false"
					href="/challenges"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Challenges</a
				> -->
				<a
					@click="showMenu = false"
					href="/rewards-and-promotions"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Rewards & Promotions</a
				>
				<a
					@click="showMenu = false"
					href="/store"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
					>Store</a
				>
				<a
					v-if="$store.state.user != null"
					@click="showMenu = false"
					href="/profile"
					class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl"
				>
					Profile
				</a>

				<a
					v-if="$store.state.user == null"
					@click="
						showMenu = false;
						handleLogin();
					"
					class="block lg:hidden px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl cursor-pointer"
				>
					Login
				</a>

				<a
					v-else
					@click="
						showMenu = false;
						handleLogout();
					"
					class="block lg:hidden px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl cursor-pointer"
					>Logout</a
				>
				<!-- <router-link @click="showMenu = false; this.isScrolled = false;" to="/challenges" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Challenges</router-link> -->
				<!-- <a href="#challenges" @click="showMenu = false" role="menuitem" class="px-4 py-1.5 routerBtn text-base font-semibold transition-colors">
                    Challenges
                </a> -->
			</div>
		</div>
		<div class="hidden lg:block">
			<div
				class="absolute left-0 right-0 mt-[72.1px] md:mt-[71px] lg:mt-2 shadow lg:flex flex-col gap-4 justify-end items-start lg:item-center p-2 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 transition-all"
				role="menu"
			>
				<div
					class="flex flex-col gap-[20px] lg:mb-0 lg:flex-row justify-evenly whitespace-nowrap text-lg text-white"
					style="font-family: Rubik, sans-serif"
				>
					<a
						v-if="$store.state.user === null"
						@click="
							showMenu = false;
							handleLogin();
						"
						class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl uppercase cursor-pointer"
					>
						Login
					</a>
					<a
						v-else
						@click="
							showMenu = false;
							handleLogout();
						"
						class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl uppercase cursor-pointer"
					>
						Logout
					</a>
				</div>
			</div>
		</div>
	</nav>
	<!-- Navbar end -->
</template>

<script>
export default {
	emits: ["logout"],
	data() {
		return {
			isScrolled: false,
			isLogout: false,
			showMenu: false,
		};
	},
	methods: {
		navigateAndCloseMenu(route) {
			this.showMenu = false;
			this.$router.push(route);
		},
		handleScroll() {
			this.isScrolled = window.scrollY > 0;

			if (this.showMenu && window.innerWidth < 768) {
				this.isScrolled = true;
			}
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
			if (this.showMenu && window.innerWidth < 768) {
				this.isScrolled = true;
			} else if (!this.showMenu && window.scrollY === 0) {
				this.isScrolled = false;
			}
		},
		isMobile() {
			return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
		},
		handleLogin() {
			const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
			window.location.href = loginUrl;
		},
		handleLogout() {
			this.$emit("logout");
		},
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll);
	},
	beforeUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	},
};
</script>

<style scoped></style>
