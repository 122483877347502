import axios from "axios";

export const getUser = async () => {
	const token = localStorage.getItem("seham-access-token");
	const response = await axios.get(process.env.VUE_APP_BASE_API + "/api/user", {
		headers: {
			"x-api-key": process.env.VUE_APP_X_API_KEY,
			Authorization: `Bearer ${token}`,
		},
	});
	return response.data;
};

export const saveUser = async (data) => {
	const token = localStorage.getItem("seham-access-token");
	const response = await axios.post(
		process.env.VUE_APP_BASE_API + "/api/user",
		data,
		{
			headers: {
				"x-api-key": process.env.VUE_APP_X_API_KEY,
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return response.data;
};
