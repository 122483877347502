<template>
    <section class="h-auto page-bg">
        <div class="container mx-auto max-w-[1150px] px-4">
            <div class="flex flex-col items-center justify-center lg:pt-28 md:pt-20 xsm:pt-20 font-russo">
                <div class="flex w-full items-center justify-center">
                    <h1 v-motion-pop-visible class="relative top-0 w-fit h-auto py-4 justify-center flex bg-gradient-to-r items-center from-green-500 via-teal-500 to-green-500 bg-clip-text text-4xl md:text-6xl font-extrabold text-transparent text-center select-auto">
                        SEHAM STORE
                    </h1>
                </div>
            </div>
            <div class="flex justify-center py-10">
                <div class="points-bg w-[200px] h-[115px] rounded-2xl">
                    <div class="mt-12 text-center font-semibold text-sm text-[#0FB97F] uppercase">Seham Points: {{ points }}</div>
                </div>
            </div>
            <div class="pb-4 border-b border-[#0FAF79]">
                <router-link to="/store" class="flex flex-row items-center gap-2">
                    <img src="../assets/back-btn.png" class="inline-flex w-5 h-5">
                    <div class="text-[rgb(15,175,121)] font-bold">Back to Store</div>
                </router-link>
            </div>
            <div class="pt-10 pb-16">
                <div class="lg:h-auto bg-gradient-to-b from-[#17CB6F] from-5% via-[#001C20] via-50% to-[#17CB6F] p-3">
                    <div class="flex flex-col lg:flex-row justify-start items-center bg-black h-auto">
                        <div class="max-w-[500px] p-6 lg:w-1/2">
                            <img :src="imageUrl" class="rounded-lg">
                        </div>
                        <div class="mx-5 lg:w-1/2 gap-3">
                            <div class="text-[#0FAF79] font-bold text-3xl">{{ title }}</div>
                            <div class="text-white font-bold text-xl mt-6">Product Description</div>
                            <div class="text-white text-lg mt-2">{{ description }}</div>
                            <div class="flex flex-col justify-center items-center my-8 gap-6">
                                <div class="text-[#05FF00] font-bold text-xl uppercase">{{ point }} Points</div>
                                <button v-if="$store.state.user != null" @click="isPurchase = true" :disabled="points < point" :class="points < point ? 'opacity-60' : 'hover:bg-[#0FAF79]'" class="w-full border border-[#0FAF79] rounded-lg py-3 uppercase text-white font-bold">
                                     {{ points < point ? "YOU DON'T HAVE ENOUGH POINTS To purchase" : 'Purchase Now' }}
                                </button>
                                <button v-else @click="isLogin = true" class="w-full border border-[#0FAF79] rounded-lg py-3 uppercase text-white font-bold hover:bg-[#0FAF79]">
                                    Login to Buy
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isLogin == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div v-motion-pop-visible class="bg-gradient-to-b from-[#17CB6F] from-5% via-[#001C20] via-50% to-[#17CB6F] p-2 rounded-xl relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                        <div class="bg-black rounded-xl px-10 pb-4 pt-5 sm:pt-8 sm:px-8 sm:pb-8">
                            <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">LOGIN REQUIRED TO MAKE A PURCHASE</h3>
                                <div class="mt-2">
                                    <p class="text-white text-sm">PLEASE LOGIN TO SEHAM.TV USING <br> YOUR DISCORD ACCOUNT</p>
                                </div>
                            </div>
                            <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2">
                                <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase clip-path-button-left main-btn">
                                Cancel
                                </button>
                                <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase clip-path-button-right main-btn">
                                Login
                                </button>
                            </div>
                            <div class="flex sm:hidden mt-4 flex-col justify-center">
                                <button @click="handleLogin()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                                Login
                                </button>
                                <button @click="isLogin = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                                Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isRetrictPurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div v-motion-pop-visible class="bg-gradient-to-b from-[#17CB6F] from-5% via-[#001C20] via-50% to-[#17CB6F] p-2 rounded-xl relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                        <div class="bg-black rounded-xl px-10 pb-4 pt-5 sm:pt-8 sm:px-8 sm:pb-8">
                            <div class="mt-3 text-center sm:mt-0 sm:text-center">
                                <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">All Transactions are closed for now</h3>
                                <div class="mt-2">
                                    <p class="text-white text-sm">PLEASE REFER TO DISCORD CHANNEL <br> FOR MORE INFO</p>
                                </div>
                            </div>
                            <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2">
                                <button @click="isRetrictPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase clip-path-button-left main-btn">
                                Close
                                </button>
                            </div>
                            <div class="flex sm:hidden mt-4 flex-col justify-center">
                                <button @click="isRetrictPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                                Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isPurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="bg-gradient-to-b from-[#17CB6F] from-5% via-[#001C20] via-50% to-[#17CB6F] p-2 rounded-xl relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="bg-black rounded-xl px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                    <div class="mt-3 text-center sm:mt-0 sm:text-center">
                        <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">are you sure you'd like to proceed with this purchase?</h3>
                        <div class="mt-4">
                            <p class="text-white text-sm uppercase">please note that all purchases made after this step are final.</p>
                            <p class="text-white text-sm uppercase">you'll recieved a ticket number for your reference.</p>
                            <p class="text-white text-sm uppercase">for any assistance, head over to discord and contact our support team.</p>
                        </div>
                    </div>
                    <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2">
                        <button v-if="loadingPurchase == false" @click="isPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase clip-path-button-left main-btn">
                         Cancel
                        </button>
                        <button v-if="loadingPurchase == false" @click="purchaseProduct()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase clip-path-button-right main-btn">
                         ConFirm
                        </button>
                        <div v-if="loadingPurchase == true" class="w-14 h-14 rounded-full animate-spin
                            border-y-8 border-solid border-white border-t-transparent">
                        </div>
                    </div>
                    <div class="flex sm:hidden mt-4 flex-col justify-center items-center">
                        <button v-if="loadingPurchase == false" @click="purchaseProduct()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                            Confirm
                        </button>
                        <button v-if="loadingPurchase == false" @click="isPurchase = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                            Cancel
                        </button>
                        <div v-if="loadingPurchase == true" class="w-14 h-14 rounded-full animate-spin
                            border-y-8 border-solid border-white border-t-transparent">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div v-show="isDonePurchase == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="bg-gradient-to-b from-[#17CB6F] from-5% via-[#001C20] via-50% to-[#17CB6F] p-2 rounded-xl relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="bg-black rounded-xl px-10 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                    <div class="mt-3 text-center sm:mt-0 sm:text-center">
                        <h3 class="text-xl lg:text-2xl font-semibold leading-6 text-white uppercase" id="modal-title">Thank you for your purchase!</h3>
                        <p class="uppercase text-base xs:text-xs text-white sm:text-sm py-5 sm:py-3 xs:py-1 mt-2">Your ticket is:</p>
                        <h3 class="text-base md:text-xl font-semibold leading-6 text-white uppercase" id="modal-title">
                            {{ this.purchaseTicket }}
                            <button @click="copyTicket()"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 -mb-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                </svg>
                            </button>
                        </h3>
                        <span class="text-sm text-green-500" v-if="copiedText">Copied to Clipboard!</span>
                        <input type="text" :value="purchaseTicket" id="purchaseTicket" class="hidden">
                        <div class="mt-8">
                            <p class="text-white text-sm uppercase"><span class="font-bold uppercase text-white">NOTE: </span>copy the ticket and head over to discord and contact support for assistance.</p>
                        </div>
                    </div>
                    <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2">
                        <button @click="isDonePurchase = false; copiedText = false;" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase  main-btn">
                        Close
                        </button>
                    </div>
                    <div class="flex sm:hidden mt-4 flex-col justify-center">
                        <button @click="isDonePurchase = false; copiedText = false;" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#0FAF79] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0FAF79] font-base relative uppercase main-btn">
                        Close
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
    export default {
        data(){
            return {
                product: null,
                title: null,
                description: null,
                point: null,
                imageUrl: null,

                points: null,
                purchaseTicket: null,
                copiedText: false,

                totalPoints: 0,

                isLogin: false,
                isPurchase: false,
                isDonePurchase: false,
                isRetrictPurchase: false,
                loadingPurchase: false
            }
        },
        methods: {
            async initProduct(){
                if(this.$store.state.product){
                    this.product = this.$store.state.product
                    this.title = this.$store.state.product.title
                    this.description = this.$store.state.product.description
                    this.point = this.$store.state.product.requiredPoints
                    this.imageUrl = this.$store.state.product.imageUrl
                }
                const baseUrl = process.env.VUE_APP_BASE_API;
                const token = localStorage.getItem('seham-access-token');
                this.loading = true

                if(token){
                    await axios
                    .get(`${baseUrl}/api/user/points`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "x-api-key": process.env.VUE_APP_X_API_KEY,
                        },
                    })
                    .then((res) => {
                        this.points = res.data.points
                    });
                }
                else{
                    this.points = '---'
                }

                this.loading = false
            },
            purchaseProduct(){
                const token = localStorage.getItem('seham-access-token');
                this.loadingPurchase = true
                let formData = {
                    productId: this.product._id
                }
                axios.post(process.env.VUE_APP_BASE_API + '/api/store/purchase', formData, {
                    headers: {
                        'x-api-key': process.env.VUE_APP_X_API_KEY,
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    if (res.data) {
                        this.purchaseTicket = res.data.ticket
                        this.isPurchase = false
                        this.loadingPurchase = false
                        this.isDonePurchase = true
                        this.initProduct()
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("seham-access-token");
                                window.location.reload();
                        }
                        if (error.response.status == 504) {
                                this.isPurchase = false
                                this.loadingPurchase = false
                                this.isRetrictPurchase = true
                        }
                        if (error.data.status == 504) {
                                this.isPurchase = false
                                this.loadingPurchase = false
                                this.isRetrictPurchase = true
                        } else {
                                console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            },
            copyTicket() {
            var copyText = document.getElementById("purchaseTicket");

                copyText.select();
                copyText.setSelectionRange(0, 99999);

                navigator.clipboard.writeText(copyText.value);

                this.copiedText = true
            },
            handleLogin() {
                const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
                window.location.href = loginUrl;
            },
        },
        mounted(){
            this.initProduct()
        }
    }
</script>

<style scoped>
.page-bg {
    background-image: url('../assets/page-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}
.points-bg {
	background-image: url("../assets/points_border.png");
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0 0 15px 5px rgba(0, 255, 0, 0.7); /* Green glow shadow */
}
</style>